import { TFACTURACION_KILOMETROS, PARAMETRO } from '@/utils/consts'

export default {
  async update (Vue, id, formData) {
    const tables = Vue.$offline.db.tables
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(id)
    await Vue.$offline.parteTrabajo.updateSync({
      idparte_trabajo: id,
      comentario_final_tecnico: formData.comentario_final_tecnico,
      comentario_final_cliente: formData.comentario_final_cliente,
      idtfacturacion_kilometros: formData.idtfacturacion_kilometros,
      manodeobrafacturable: formData.manodeobrafacturable,
      ffijada: formData.ffijada,
      idalmacen: formData.idalmacen,
      fprevista_fin: formData.fprevista_fin,
    })
    await Vue.$offline.ordenTrabajo.updateSync({
      idorden_trabajo: parteTrabajo.parte_trabajo.idorden_trabajo,
      idtmotivo_bloqueo_ot: formData.idtmotivo_bloqueo_ot,
      notas_internas: formData.notas_internas,
    })
    const tiempoTrabajado = Vue.$offline.tiempoTrabajado
    const tiempos = await Vue.$offline.db
      .select()
      .from(tables.tiempo_trabajado)
      .innerJoin(tiempoTrabajado.dummyTable, tiempoTrabajado.pk.eq(tiempoTrabajado.dummyPk))
      .where(tables.tiempo_trabajado.idparte_trabajo.eq(id))
      .exec()
    const manoObraDesplazamientoFacturable = (await Vue.$offline.parametro.valorParametro(
      PARAMETRO.parametros.MANODEOBRA_DESPLAZAMIENTO_FACTURABLE_DEFECTO
    )) === '1'
    for (let tiempo of tiempos) {
      if (tiempo.tiempo_trabajado.es_desplazamiento) {
        await Vue.$offline.tiempoTrabajado.updateSync({
          idtiempo_trabajado: tiempo.tiempo_trabajado.idtiempo_trabajado,
          // tiempo de desplazamiento facturable según parámetro
          manodeobrafacturable: manoObraDesplazamientoFacturable,
          kilometros_facturables: formData.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosTecnico,
        })
      } else {
        await Vue.$offline.tiempoTrabajado.updateSync({
          idtiempo_trabajado: tiempo.tiempo_trabajado.idtiempo_trabajado,
          // tiempo de mano de obra facturable según parte
          manodeobrafacturable: formData.manodeobrafacturable,
          kilometros_facturables: formData.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosTecnico,
        })
      }
    }
  },
}
